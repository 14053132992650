@import "variables";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import "./layout/sidebar";
@import "./layout/container";

.logoMobile{
    display: none;

    @media screen and (max-width: 992px) {
        display: block;
    }
}
.about_us_image{
    object-fit: contain;
    max-width: 310px;
}

.loading_div{
    width: 100px;
    position: relative;
    background-color: black;
    color: white;
    
    &::before{
        content: '';
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        mix-blend-mode: difference;
        background-color: white;
        animation: loading 2s infinite 2s alternate cubic-bezier(0.63, 0.07, 0.15, 0.57) forwards;
    }
}

@keyframes loading {
    0%   { 
            left: 0%; 
        }
    100%{
        left: calc(100% - 50px);
    }
}

.user_card{
    text-decoration: none;
    color: inherit;
    width: 500px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    vertical-align: inherit;
}

.projects_count{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 1px solid green;
}

Link, a{
    text-decoration: none;
    color: inherit;
}

.cards_data {
    display: flex;
    justify-content: center;
    align-items: center;
    // max-width: 700px;
    // width: 100%;
}

.card_links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #026bdb;
}

.add_admin{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.add_admin_modal{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.invalid-Input-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #f62d51;
}

.invalid-Input-feedback-validated {
    border-color: #f62d51;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}