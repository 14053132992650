.pageWrapper {
  height: 93vh;
  overflow: hidden;
}
.contentArea {
  flex-grow: 1;
  max-width: 1300px;
  margin: 0 auto;
  height: 93vh;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    display: none;
  }
}

.top-nav-div{
  height: 7vh;
}

.card {
  box-shadow: $card-box-shadow;
  margin-bottom: 30px;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}
.button-group .btn {
  margin: 3px;
}
table th {
  font-weight: 500;
}
